<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("inactivation_teams") }}</h1>
      <br>
      <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="teams">
  
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
  
          <div class="flex flex-wrap-reverse items-center data-list-btn-container">
            <!-- ADD NEW -->
           <!--  <div class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary" @click="addNewData">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Ajouter</span>
            </div> -->
        
          </div>
  
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ teams.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : teams.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
  
              <vs-dropdown-item @click="itemsPerPage=20">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=40">
                <span>40</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=60">
                <span>60</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=80">
                <span>80</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
  
        <template slot="thead">
          <vs-th sort-key="teams">{{ $t("teams") }}</vs-th>
          <vs-th sort-key="leader">{{ $t("leader") }}</vs-th>
          <vs-th sort-key="company">{{ $t("company") }}</vs-th>
          <vs-th sort-key="staffs">{{ $t("staffs") }}</vs-th>
          <vs-th sort-key="tasks">{{ $t("tasks") }}</vs-th>
          <vs-th sort-key="vehicles">{{ $t("vehicles") }}</vs-th>
          <vs-th sort-key="liftingEquipments">{{ $t("liftingEquipments") }}</vs-th>
          <vs-th sort-key="greyCard">{{ $t("nokiaValidation") }}</vs-th>
          <vs-th>Action</vs-th>
        </template>
  
        <template slot-scope="{data}">
          <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.name}}</p>
            </vs-td>
            <vs-td >
                <p class="product-name font-medium truncate">{{ tr.teamLeader !== null ? tr.teamLeader.last_name : '-'}}</p>
            </vs-td>
            <vs-td >
                <p class="product-name font-medium truncate">{{ tr.company !== null ? tr.company.name : '-'}}</p>
            </vs-td>
            <vs-td>
              <li v-for="staff in tr.staffs" :key="staff" class="product-name font-medium truncate">{{ staff.fullName}}{{" "}}({{ staff.function.name}})</li>
            </vs-td>
            <vs-td>
              <li v-for="task in tr.tasks" :key="task" class="product-name font-medium truncate">{{ task.name}}</li>
            </vs-td>
            <vs-td>
              <li v-for="vehicle in tr.vehicles" :key="vehicle" class="product-name font-medium truncate">{{ vehicle.name}}({{ vehicle.plateNumber }})</li>
            </vs-td>
            <vs-td>
              <li v-if="liftingEquipment.otherNorm" v-for="liftingEquipment in tr.liftingEquipments" :key="liftingEquipment" class="product-name font-medium truncate">{{ liftingEquipment.equipmentType.name}}-{{ liftingEquipment.otherNorm}}-({{liftingEquipment.number}})</li>
              <li v-if="!liftingEquipment.otherNorm" v-for="liftingEquipment in tr.liftingEquipments" :key="liftingEquipment" class="product-name font-medium truncate">{{ liftingEquipment.equipmentType.name}}-{{ liftingEquipment.equipmentType.norm}}-({{liftingEquipment.number}})</li>
            </vs-td>
            <vs-td>
            <vs-chip v-if="getlang ==='fr'" :color="getOrderStatusColor(tr.activated)" class="product-order-status">{{validationObject[tr.activated]}}</vs-chip>
            <vs-chip v-if="getlang ==='en'" :color="getOrderStatusColor(tr.activated)" class="product-order-status">{{validationObject_en[tr.activated]}}</vs-chip>
            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <div class="flex">
               <!--  <vx-tooltip  text="Voir" color="#28C76F">
                  <feather-icon style="color: #28C76F " icon="ImageIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"   @click="openLightbox" @click.stop="Show_image(tr)" color="warring" type="border" class="mr-2" />
                </vx-tooltip> -->
                <vx-tooltip :text="$t('Voir')" color="primary">
                  <feather-icon style="color: #0627bb" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="mr-2" @click="ShowData(tr)"/>
                </vx-tooltip>
                <vx-tooltip :text="$t('Modifier')" color="warning">
                  <feather-icon style="color: #FF9F43" icon="EditIcon" svgClasses="w-5 h-5 hover:text-warning stroke-current" class="mr-2" @click="editData(tr)"/>
                </vx-tooltip>
  <!--              <vx-tooltip v-if="profile_moov!=='studio'" text="Supprimer" color="danger">-->
                <vx-tooltip :text="$t('Supprimer')" color="danger">
                  <feather-icon @click="deleteData(tr.id)" style="color: red" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
                </vx-tooltip>
                <vx-tooltip v-if="user_role !== 'managerSubcontractor'" :text="$t('valider_equipe')"  color="success">
                <feather-icon @click="comfirmation(tr.id)" style="color: #06ac0e" icon="CheckCircleIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" class="mr-2"/>
              </vx-tooltip>
              <vx-tooltip v-if="user_role !== 'managerSubcontractor'" :text="$t('rejeter_equipe')" color="danger">
                <feather-icon @click="rejet_Pop(tr.id)" style="color: #ff6141" icon="XCircleIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="mr-2"/>
              </vx-tooltip>
              <vx-tooltip v-if="tr.activated === 0 " :text="$t('rapport')" color="dark">
                <feather-icon @click="rapport(tr.teamComments)" style="color: black" icon="FileTextIcon" svgClasses="w-5 h-5 hover:text-dark stroke-current" class="mr-2"/>
              </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
          </tbody>
          
        </template>
      </vs-table>
      <vs-popup
      v-if="activePrompt1" 
      fullscreen
        class="calendar-event-dialog"
        :title="$t('ajouter_equipe')"
        :active.sync="activePrompt1">
        <div class="clearfix">

          <div class="vx-row">
            <div class="vx-col md:w-1/3 sm:w-1/3 lg:1/3 xs:w-1/3">
              <p>{{ $t("equipe_name") }}<b style="color: #ff6141">*</b></p>
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="name"
                  v-model="name"
                  class="w-full"
              />
                <span class="text-danger text-sm" v-show="errors.has('name')">{{ $t("name_error") }}</span>
            </div>
            
            <div class="vx-col md:w-1/3 sm:w-1/3 lg:1/3 xs:w-1/3">
              <p>{{ $t("teamLeader") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select 
                  :closeOnSelect="false"
                  v-model="teamLeader"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="teamLeader"
                  class="w-full"
              >
            <vs-select-item :key="tem" :value="tem.id" :text="tem.last_name" v-for="tem in teamLeaders" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('teamLeader')">{{ $t("teamLeader_error") }}</span>
            
            </div>
            <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
              <p>{{ $t("company") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select 
                  :disabled="role_dis"
                  :closeOnSelect="false"
                  v-model="company"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="company"
                  class="w-full"
              >
            <vs-select-item :key="tem1" :value="tem1.id" :text="tem1.name" v-for="tem1 in companies" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('company')">{{ $t("company_error") }}</span>
            
            </div>
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
         
             <p>{{ $t("staffs") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select
                  multiple 
                  :closeOnSelect="false"
                  v-model="staff"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="staff"
                  class="w-full"
              >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.fullName" v-for="item1 in staffs" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('staff')">{{ $t("staff_error") }}</span>
            
            </div>
    
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
  
              <p>{{ $t("tasks") }}<b style="color: #ff6141" ></b> </p>
                <vs-select
                  multiple 
                  :closeOnSelect="false"
                  v-model="task"
                  data-vv-validate-on="blur"
                  autocomplete
                  name="task"
                  class="w-full"
              >
            <vs-select-item :key="item2" :value="item2.id" :text="item2.name" v-for="item2 in tasks" /></vs-select>
<!--             <span class="text-danger text-sm" v-show="errors.has('task')">{{ $t("task") }}</span>
 -->            </div>
    
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
              <p>{{ $t("vehicles") }}<b style="color: #ff6141" >*</b> </p>
                  <vs-select
                    multiple 
                    v-validate="'required'"
                    :closeOnSelect="false"
                    v-model="vehicle"
                    autocomplete
                    name="vehicle"
                    class="w-full"
                >
              <vs-select-item :key="item3" :value="item3.id" :text="item3.plateNumber" v-for="item3 in vehicles" /></vs-select>
              <span class="text-danger text-sm" v-show="errors.has('vehicle')">{{ $t("vehicle_error") }}</span> 
            
            </div>

            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("liftingEquipments") }}<b style="color: #ff6141" >*</b></p>
                <vs-select
                  multiple 
                  v-validate="'required'"
                  :closeOnSelect="false"
                  v-model="liftingEquipment"
                  autocomplete
                  name="liftingEquipment"
                  class="w-full"
              >
            <vs-select-item :key="item4" :value="item4.id" :text="item4.name" v-for="item4 in liftingEquipments" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('liftingEquipment')">{{ $t("liftingEquipment_error") }}</span>
           </div>

          </div>
          <vs-button color="success"  class="float-right mt-4" @click="submitData()" >{{ $t("Soumettre") }}</vs-button>
        </div>
      </vs-popup>
      
      <vs-popup
      v-if="activePrompt2" 
        fullscreen
        class="calendar-event-dialog"
        :title="$t('modifier_equipe')"
        :active.sync="activePrompt2">
        <div class="clearfix">

          <div class="vx-row">
            <div class="vx-col md:w-1/3 sm:w-1/3 lg:1/3 xs:w-1/3">
              <p>{{ $t("equipe_name") }}<b style="color: #ff6141">*</b></p>
                <vs-input
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="name"
                  v-model="name"
                  class="w-full"
              />
                <span class="text-danger text-sm" v-show="errors.has('name')">{{ $t("name_error") }}</span>
            </div>

            <div class="vx-col md:w-1/3 sm:w-1/3 lg:1/3 xs:w-1/3">
              <p>{{ $t("teamLeader") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select 
                  :closeOnSelect="false"
                  v-model="teamLeader"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="teamLeader"
                  class="w-full"
              >
            <vs-select-item :key="tem" :value="tem.id" :text="tem.last_name" v-for="tem in teamLeaders" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('teamLeader')">{{ $t("teamLeader_error") }}</span>
            
            </div>

            <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
              <p>{{ $t("company") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select 
                  :disabled="role_dis"
                  :closeOnSelect="false"
                  v-model="company"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="company"
                  class="w-full"
              >
            <vs-select-item :key="tem1" :value="tem1.id" :text="tem1.name" v-for="tem1 in companies" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('company')">{{ $t("company_error") }}</span>
            </div>
            
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
         
             <p>{{ $t("staffs") }}<b style="color: #ff6141" >*</b> </p>
                <vs-select
                  multiple 
                  :closeOnSelect="false"
                  v-model="staff"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="staff"
                  class="w-full"
              >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.fullName" v-for="item1 in staffs" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('staff')">{{ $t("staff_error") }}</span>
            
            </div>
    
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
  
              <p>{{ $t("tasks") }}<b style="color: #ff6141" ></b> </p>
                <vs-select
                  multiple 
                  :closeOnSelect="false"
                  v-model="task"
                  data-vv-validate-on="blur"
                  autocomplete
                  name="task"
                  class="w-full"
              >
            <vs-select-item :key="item2" :value="item2.id" :text="item2.name" v-for="item2 in tasks" /></vs-select>
<!--             <span class="text-danger text-sm" v-show="errors.has('task')">{{ $t("task") }}</span>
 -->            </div>
    
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
              <p>{{ $t("vehicles") }}<b style="color: #ff6141" >*</b> </p>
                  <vs-select
                    multiple 
                    v-validate="'required'"
                    :closeOnSelect="false"
                    v-model="vehicle"
                    autocomplete
                    name="vehicle"
                    class="w-full"
                >
              <vs-select-item :key="item3" :value="item3.id" :text="item3.plateNumber" v-for="item3 in vehicles" /></vs-select>
              <span class="text-danger text-sm" v-show="errors.has('vehicle')">{{ $t("vehicle_error") }}</span> 
            
            </div>

            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("liftingEquipments") }}<b style="color: #ff6141" >*</b></p>
                <vs-select
                  multiple 
                  v-validate="'required'"
                  :closeOnSelect="false"
                  v-model="liftingEquipment"
                  autocomplete
                  name="liftingEquipment"
                  class="w-full"
              >
            <vs-select-item :key="item4" :value="item4.id" :text="item4.name" v-for="item4 in liftingEquipments" /></vs-select>
            <span class="text-danger text-sm" v-show="errors.has('liftingEquipment')">{{ $t("liftingEquipment_error") }}</span>
           </div>

          </div>
          <vs-button color="success"  class="float-right mt-4" @click="submitData()" >{{ $t("Soumettre") }}</vs-button>
        </div>
      </vs-popup>
 
      <vs-popup
      v-if="activePrompt3" 
      fullscreen
        class="calendar-event-dialog"
        :title="$t('details_equipe')"
        :active.sync="activePrompt3">
        <div class="clearfix">

          <div class="vx-row">
            <div class="vx-col md:w-1/3 sm:w-1/3 lg:1/3 xs:w-1/3">
              <p>{{ $t("equipe_name") }}<b style="color: #ff6141"></b></p>
                <vs-input
                  disabled
                  v-validate="'required'"
                  data-vv-validate-on="blur"
                  name="name"
                  v-model="name"
                  class="w-full"
              />
            </div>

            <div class="vx-col md:w-1/3 sm:w-1/3 lg:1/3 xs:w-1/3">
              <p>{{ $t("teamLeader") }}<b style="color: #ff6141" ></b> </p>
                <vs-select 
                  disabled
                  :closeOnSelect="false"
                  v-model="teamLeader"
                  data-vv-validate-on="blur"
                  autocomplete
                  name="teamLeader"
                  class="w-full"
              >
            <vs-select-item :key="tem" :value="tem.id" :text="tem.last_name" v-for="tem in teamLeaders" /></vs-select>
            </div>
            <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/3">
              <p>{{ $t("company") }}<b style="color: #ff6141" ></b> </p>
                <vs-select 
                  disabled
                  :closeOnSelect="false"
                  v-model="company"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  autocomplete
                  name="company"
                  class="w-full"
              >
            <vs-select-item :key="tem1" :value="tem1.id" :text="tem1.name" v-for="tem1 in companies" /></vs-select>
            </div>
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
         
             <p>{{ $t("staffs") }}<b style="color: #ff6141" ></b> </p>
                <vs-select
                  disabled
                  multiple 
                  :closeOnSelect="false"
                  v-model="staff"
                  autocomplete
                  name="staff"
                  class="w-full"
              >
            <vs-select-item :key="item1" :value="item1.id" :text="item1.fullName" v-for="item1 in staffs" /></vs-select>
            
            </div>
    
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
  
              <p>{{ $t("tasks") }}<b style="color: #ff6141" ></b> </p>
                <vs-select
                  disabled
                  multiple 
                  :closeOnSelect="false"
                  v-model="task"
                  autocomplete
                  name="task"
                  class="w-full"
              >
            <vs-select-item :key="item2" :value="item2.id" :text="item2.name" v-for="item2 in tasks" /></vs-select>
            </div>
    
            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
              <p>{{ $t("vehicles") }}<b style="color: #ff6141" ></b> </p>
                  <vs-select
                    disabled
                    multiple 
                    :closeOnSelect="false"
                    v-model="vehicle"
                    autocomplete
                    name="vehicle"
                    class="w-full"
                >
              <vs-select-item :key="item3" :value="item3.id" :text="item3.plateNumber" v-for="item3 in vehicles" /></vs-select>
            </div>

            <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <p>{{ $t("liftingEquipments") }}<b style="color: #ff6141" ></b> </p>
                <vs-select
                  disabled
                  multiple 
                  :closeOnSelect="false"
                  v-model="liftingEquipment"
                  autocomplete
                  name="liftingEquipment"
                  class="w-full"
              >
            <vs-select-item :key="item4" :value="item4.id" :text="item4.name" v-for="item4 in liftingEquipments" /></vs-select>
            </div>

        </div>
          <vs-button color="primary"  class="float-right mt-4" @click="quit()" >Quitter</vs-button>
        </div>
      </vs-popup>

      <vs-popup classContent="popup-example" :title="$t('ajouter_votre_commentaire')" :active.sync="activePrompt4">
      <vs-textarea
        v-model="comment"
        rows="5"
        class="w-full" >
      </vs-textarea>
      <div class="flex flex-wrap justify-between mt-5 ">
      <vs-button   color="danger" type="filled" icon-pack="feather" icon="icon-minus-circle" @click="resetData_comfirmation">{{ $t("Annuler") }}</vs-button>
      <vs-button  :disabled="!validate_comment" color="success" type="filled" icon-pack="feather" icon="icon-plus-circle" @click="rejete_comfirmation">{{ $t("Valider") }}</vs-button>
      </div>
      </vs-popup>

      <vs-popup classContent="popup-example" :title="$t('details_du_rejet')" :active.sync="activePrompt5">
        
        <li class="mb-2" v-for="comment in details" :key="comment" >{{ comment.comment }}</li>

      </vs-popup>
      
    </div>
  </template>
<script>

import { Validator } from 'vee-validate'

const dict = {
  custom: {
    name: {
      required: 'Le champ nom d\'équipe  est obligatoire'
    },
    vehicle: {
      required: 'Le champ véhicule est obligatoire'
    }, 
    staff: {
      required: 'Le champ personnel est obligatoire'
    },
    task: {
      required: 'Le champ tâche est obligatoire'
    },
    liftingEquipment: {
      required: 'Le champ équipement de levage est obligatoire'
    }
  }
}
Validator.localize('fr', dict)
export default {
  data () {
    return {
      user_role:'',
      activePrompt5:false,
      activePrompt4:false,
      comment : '',
      team_id : '',
      validationObject: {
        0: 'Rejetée',
        1: 'Encours',
        2: 'Validée'
      },
      validationObject_en: {
        0: 'Rejected',
        1: 'Verification',
        2: 'Valid'
      },
      details :[],
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      Check_update:false,
      id:'',
      vehicle: [],
      task:[],
      staff: [],
      liftingEquipment:[],
      teams: [],
      vehicles: [],
      tasks:[],
      staffs: [],
      liftingEquipments:[],
      selected: [],
      isMounted: false,
      itemsPerPage: 20
     
    }
  },
  computed: {
    getlang () {
      return window.getPrendTaCom.$i18n.locale
    },
    validate_comment () {
      if (
        (
          this.comment !== ''  
        )) return true
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.teams.length
    }
  },
  watch: {
    vehicle (value) {
      if (value) {
        /* console.log('voir', value)
        console.log('id', value[length].id)
        console.log('lenght', value.length)
 */
        /* console.log('voir value', value.id)
 */
      } 
    }
  },
  methods: {
    rapport (tr) {
      this.details = []
      this.activePrompt5 = true
      this.details = tr
    },
    rejete_comfirmation () {
      const input = {
        team : this.team_id,
        comment : this.comment
      }
      //const vehicle = this.team_id
      //const comment = this.comment
      this.$vs.loading()
      this.$http.post('reject-team/', input)
        .then(response => {
          this.getAllTeams()
          window.getPrendTaCom.success(this.$t('rejete_equipe'), response)
        })
        .catch(() => {
          window.getPrendTaCom.error({ message:this.$t('echec_rejet_equipe') })
        })
      this.activePrompt4 = false
    },
    comfirmation (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation',
        text: this.$t('comfirm_validation'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          const team = id
          this.$vs.loading()
          this.$http.post('validate-team/', {team})
            .then(response => {
              this.getAllTeams()
              window.getPrendTaCom.success(this.$t('valide_equipe'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message:this.$t('echec_validation_equipe')})
            })
        }
      })
    },

    resetData_comfirmation () {
      this.comment = ''
      this.team_id = ''
      this.activePrompt4 = false
    },
    rejet_Pop (id) {
      this.team_id = id
      this.comment = ''
      this.activePrompt4 = true
    },
    getOrderStatusColor (status) {
      if (status === 0) {
        return 'danger'
      } else if (status === 1) {
        return 'warning'
      } else if (status === 2) {
        return 'success'
      }
    },
    addNewData () {
      this.$validator.reset()
      this.intialise()
      this.Check_update = false
      this.activePrompt1 = true
    },
    quit () {
      this.activePrompt3 = false
      this.$validator.reset()
      this.intialise()
    },
    ShowData (data) {
      this.intialise()
      this.name = data.name
      for (let i = 0; i < data.staffs.length; i++) {
        this.staff.push(data.staffs[i].id)
         
      } 
      for (let i = 0; i < data.tasks.length; i++) {
        this.task.push(data.tasks[i].id)
         
      }
      for (let i = 0; i < data.vehicles.length; i++) {
        this.vehicle.push(data.vehicles[i].id)
         
      } 
      for (let i = 0; i < data.liftingEquipments.length; i++) {
        this.liftingEquipment.push(data.liftingEquipments[i].id)
      } 
      this.activePrompt3 = true
    },
    editData (data) {
      this.intialise()
      this.id = data.id
      this.name = data.name
      for (let i = 0; i < data.staffs.length; i++) {
        this.staff.push(data.staffs[i].id)
         
      } 
      for (let i = 0; i < data.tasks.length; i++) {
        this.task.push(data.tasks[i].id)
         
      }
      for (let i = 0; i < data.vehicles.length; i++) {
        this.vehicle.push(data.vehicles[i].id)
         
      } 
      for (let i = 0; i < data.liftingEquipments.length; i++) {
        this.liftingEquipment.push(data.liftingEquipments[i].id)
      } 
      this.Check_update = true
      this.activePrompt2 = true
    },
   
    intialise () {
      this.id = ''
      this.name = ''
      this.staff = []
      this.task = []
      this.vehicle = []
      this.liftingEquipment = []
      this.Check_update = false
      this.activePrompt1 = false
      this.activePrompt2 = false

    },
      
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`teams/${id}/`)
            .then(response => {
              this.getAllTeams()
              window.getPrendTaCom.success(this.$t('Suppressionequipe'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression') })
            })
        }
      })
  
    },
    submitData () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading()
          if (this.Check_update === false) {
            this.input = {
              name : this.name,
              teamLeader:this.teamLeader,
              company : this.company,
              tasks : this.task,
              staffs : this.staff,
              vehicles : this.vehicle,
              liftingEquipments : this.liftingEquipment
            }
          } else if (this.Check_update === true) {
            this.input = {
              id:this.id,
              name : this.name,
              teamLeader:this.teamLeader,
              company : this.company,
              tasks : this.task,
              staffs : this.staff,
              vehicles : this.vehicle,
              liftingEquipments : this.liftingEquipment
            }
          }
          let url = 'teams/'
          let methods = 'post'
          const message = {
            error: this.$t('save_error'),            
            success: this.$t('equipe_save')
          }
          if (this.id) {
            url += `${this.id}/`
            methods = 'put'
            message.success = this.$t('equipe_update')
          }
  
          this.$http[methods](url, this.input)
            .then((response) => {
              window.Teams.getAllTeams()
              window.getPrendTaCom.success(message.success, response)
              this.intialise()
            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
                if (item === 'name') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('name'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'tasks') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('tasks'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'staffs') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('staffs'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'vehicles') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('vehicles'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'liftingEquipments') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('liftingEquipments'),
                    color: 'warning',
                    position: 'top-center'
                  })
                }
              }
              this.$vs.notify({
                time: 8000,
                title: 'ENREGISTREMENT',
                text: error.response.data.error,
                color: 'danger',
                position: 'top-right'
              })
              this.$vs.loading.close()
            })
        }
      })
    },
    getAllVehicles () {
      let assigned = ''
      this.$vs.loading()
      this.$http.get('vehicles/')
        .then((response) => {
          const results = response.data
          results.forEach(event => {
            if (event.assigned) {
              assigned = '(1)'
            } else {
              assigned = '(0)'
            }
            this.vehicles.push({
              id: event.id,
              plateNumber:(`${event.plateNumber}${assigned}`)
            })
              
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllLiftingEquipments () {
      this.$vs.loading()
      let assigned = ''
      let norm = ''
      this.$http.get('liftingEquipments/')
        .then((response) => {
          const results = response.data
          results.forEach(event => {
            if (event.assigned) {
              assigned = '(1)'
            } else {
              assigned = '(0)'
            }
            if (event.otherNorm) {
              norm = event.otherNorm
            } else {
              norm = event.equipmentType.norm
            }
            this.liftingEquipments.push({
              id: event.id,
              name:(`${event.equipmentType.name}-${norm}-${event.number}${assigned}`)
            })
              
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllstaffs () {
      this.$vs.loading()
      this.$http.get('staffs/')
        .then((response) => {
          const results = response.data
          results.forEach(event => {
            this.staffs.push({
              id: event.id,
              fullName:(`${event.fullName}-(${event.function.name})`)
            })
              
          })
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAlltasks () {
      this.$vs.loading()
      this.$http.get('tasks/')
        .then((response) => {
          this.tasks = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllTeams () {
      this.$vs.loading()
      this.$http.get('inactivation-teams/')
        .then((response) => {
          this.teams = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  
  },
  created () {
    this.user_role = JSON.parse(localStorage.getItem('userInfo')).role
    window.Teams = this
    this.getAllTeams()
    this.getAlltasks()
    this.getAllstaffs()
    this.getAllVehicles()
    this.getAllLiftingEquipments()
  },
  mounted () {
    this.isMounted = true
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  <style scoped>
  
  </style>
  